import {
  fetchInvoices,
  createInvoices,
  issueInvoice,
  syncBills,
  sendEmails,
  cancelInvoice,
} from '../../../../resources/admin/invoices';

const types = {
  UPDATE_INVOICES: 'UPDATE_INVOICES',
  CREATE_INVOICES: 'CREATE_INVOICES',
  CANCEL_INVOICES: 'CANCEL_INVOICES',
  ISSUE_INVOICES: 'ISSUE_INVOICES',
  SEND_EMAILS: 'SEND_EMAILS',
  UPDATE_DUE_DATE: 'UPDATE_DUE_DATE',
};

const mutations = {
  [types.UPDATE_INVOICES](state, data) {
    if (data) {
      if (data.invoices && Array.isArray(data.invoices)) {
        state.invoices = data.invoices;
      }
      if (data.summary) {
        state.summary = data.summary;
      }
    }
  },
  [types.CREATE_INVOICES](state, data) {
    if (data && Array.isArray(data)) {
      const totalValorFaturar = data.reduce((sum, dataItem) => {
        const invoice = state.invoices.find(
          i => i.ofertaCompraFornecedorId === dataItem.ofertaCompraFornecedorId,
        );
        return invoice ? sum + invoice.valorFaturar : sum;
      }, 0);
      state.summary.semNota += totalValorFaturar;
      state.summary.semFatura -= totalValorFaturar;
      state.invoices = state.invoices.filter(invoice => {
        return !data.some(
          item => item.ofertaCompraFornecedorId === invoice.ofertaCompraFornecedorId,
        );
      });
    }
  },
  [types.CANCEL_INVOICES](state, data) {
    if (data && Array.isArray(data)) {
      const totalValorFaturar = data.reduce((sum, dataItem) => {
        const invoice = state.invoices.find(
          i => i.ofertaCompraFornecedorId === dataItem.ofertaCompraFornecedorId,
        );
        return invoice ? sum + invoice.valorFaturar : sum;
      }, 0);
      state.summary.semFatura += totalValorFaturar;
      state.summary.semNota -= totalValorFaturar;
      state.invoices = state.invoices.filter(invoice => {
        return !data.some(
          item => item.ofertaCompraFornecedorId === invoice.ofertaCompraFornecedorId,
        );
      });
    }
  },
  [types.ISSUE_INVOICES](state, data) {
    if (data && Array.isArray(data)) {
      const total = data.reduce((sum, dataItem) => {
        const invoice = state.invoices.find(
          i => i.ofertaCompraFornecedorId === dataItem.ofertaCompraFornecedorId,
        );
        return invoice ? sum + invoice.valorFaturar : sum;
      }, 0);
      state.summary.semBoleto += total;
      state.summary.semNota -= total;
      state.invoices = state.invoices.filter(invoice => {
        return !data.some(
          item => item.ofertaCompraFornecedorId === invoice.ofertaCompraFornecedorId,
        );
      });
    }
  },
  [types.SEND_EMAILS](state, data) {
    if (data && Array.isArray(data)) {
      const total = data.reduce((sum, dataItem) => {
        const invoice = state.invoices.find(i => i.faturaId === dataItem);
        return invoice ? sum + invoice.valorFaturar : sum;
      }, 0);
      state.summary.semBoleto += total;
      state.summary.semEmail -= total;
      state.invoices = state.invoices.filter(invoice => {
        return !data.some(
          item => item.ofertaCompraFornecedorId === invoice.ofertaCompraFornecedorId,
        );
      });
    }
  },
  [types.UPDATE_DUE_DATE](state, novoVencimento) {
    const newValue = new Date(novoVencimento);
    state.invoices = state.invoices.map(invoice => {
      const invoiceDate = new Date(invoice.dataVencimento);
      if (invoice.dataVencimento !== null && invoiceDate < newValue) {
        return {
          ...invoice,
          dataVencimento: novoVencimento,
        };
      }
      return invoice;
    });
  },
};

const actions = {
  async fetchInvoices({ commit }, filters) {
    const response = await fetchInvoices(filters);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.UPDATE_INVOICES, response.data);
  },
  async createInvoices({ commit }, data) {
    const response = await createInvoices(data);
    if (response.status !== 204) return Promise.reject(response.data);
    return commit(types.CREATE_INVOICES, data);
  },
  async issueInvoice({ commit }, data) {
    const response = await issueInvoice(data);
    if (response.status !== 204) return Promise.reject(response.data);
    return commit(types.ISSUE_INVOICES, data);
  },
  async cancelInvoice({ commit }, data) {
    const response = await cancelInvoice(data);
    if (response.status !== 204) return Promise.reject(response.data);
    return commit(types.CANCEL_INVOICES, data);
  },
  async syncBills() {
    const response = await syncBills();
    if (response.status !== 204) return Promise.reject(response.data);
    return Promise.resolve();
  },
  async sendEmails({ commit }, data) {
    const response = await sendEmails(data);
    if (response.status !== 204) return Promise.reject(response.data);
    return commit(types.SEND_EMAILS, data);
  },
  updateDueDate({ commit }, novoVencimento) {
    commit(types.UPDATE_DUE_DATE, novoVencimento);
  },
};

const getters = {
  filteredInvoices: state => dataVencimento => {
    if (dataVencimento === null) {
      return state.invoices;
    }
    return state.invoices.filter(invoice => invoice.dataVencimento === dataVencimento);
  },
};

const state = {
  invoices: [],
  invoice: {},
  summary: {},
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
