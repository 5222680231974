var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buyer-finished-container"},[_vm._m(0),(!_vm.data.length)?_c('div',{staticClass:"panel"},[_vm._m(1)]):_vm._e(),(_vm.data.length)?_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-block"},[_c('b-table',{staticClass:"table is-fullwidth table-typography-size buyer-finished-table",attrs:{"data":_vm.data,"loading":_vm.tableLoading},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('AppPagination',{attrs:{"data-filters":{
              page: _vm.filters.page,
              perPage: _vm.filters.perPage,
            },"isStateURLEnabled":false,"number-lines":10,"total":_vm.count},on:{"change-page":function (page) { return _vm.changePage(page); },"change-per-page":function (perPage) { return _vm.changePerPage(perPage); }}})]},proxy:true}],null,false,639539237)},[_c('b-table-column',{attrs:{"sortable":"","field":"type","label":"Tipo"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":props.row.type === 'dispensation' ? 'Dispensa' : 'Pregão',"position":_vm.mobile ? 'is-left' : 'is-top'}},[_c('b-icon',{staticClass:"custom-icon",attrs:{"type":"is-primary","icon":props.row.type === 'dispensation' ? 'alpha-d-box' : 'alpha-p-box'}})],1)]}}],null,false,1343586477)}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"biddingNumber","label":"Licitação"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.situationId > 1)?_c('router-link',{attrs:{"to":{
              name: props.row.type === 'dispensation' ? 'Dispensa' : 'Pregao',
              params: { id: props.row.id },
            }}},[_vm._v(" "+_vm._s(props.row.biddingNumber)+" ")]):_c('p',[_vm._v(_vm._s(props.row.biddingNumber))])]}}],null,false,3629978528)}),_c('b-table-column',{attrs:{"centered":"","label":"Itens com Ações Restantes","visible":!_vm.tableLoading &&
            _vm.data.some(
              function (bidding) { return bidding.missingAdjudications ||
                bidding.missingHomologations ||
                bidding.missingRatifications; }
            )},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.getMissingValidations(props.row))+" ")]}}],null,false,2881682776)})],1)],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-container is-flex"},[_c('h2',{staticClass:"title is-4"},[_vm._v("Encerradas")]),_c('h4',{staticClass:"subtitle is-6 is-italic ml-4",attrs:{"id":"custom-subtitle"}},[_vm._v(" licitações com itens não finalizados ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel-block is-justify-content-center"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_vm._v("Nenhuma licitação.")])])])])}]

export { render, staticRenderFns }