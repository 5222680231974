import { api } from './http';

export const stats = () => api.get('/ofertascompra/stats');

export const todas = filters => {
  let params = '?';
  if (filters) {
    if (filters.situation) params += `situacao=${filters.situation}&`;
    if (filters.buyerId) {
      params += `cidade=${filters.buyerId}&`;
    }
    if (filters.period) {
      if (filters.period.start) {
        params += `inicio=${filters.period.start.toISOString()}&`;
      }
      if (filters.period.end) {
        params += `fim=${filters.period.end.toISOString()}&`;
      }
    }
    if (filters.number) params += `numero=${filters.number}&`;
    if (filters.indexed) {
      params += `cadastradas=${filters.indexed}&`;
    }
    if (filters.page) params += `pagina=${filters.page}&`;
    if (filters.perPage) params += `por_pagina=${filters.perPage}&`;
  }
  return api.get(`/ofertascompra${params}`);
};
export const get = ofertaCompraId => api.get(`/ofertascompra/${ofertaCompraId}`);
export const criar = () => api.post('/ofertascompra');
export const editAuction = (auctionId, data) => api.patch(`/ofertascompra/${auctionId}`, data);
export const encerrarAnalise = ofertaCompraId =>
  api.patch(`ofertascompra/${ofertaCompraId}/encerrar-analise`);
export const encerrarLances = ofertaCompraId =>
  api.patch(`ofertascompra/${ofertaCompraId}/encerrar-etapa-lances`);
export const voltarParaAnaliseDePropostas = ofertaCompraId =>
  api.patch(`ofertascompra/${ofertaCompraId}/voltar-para-analise`);
export const reabrirSessao = ofertaCompraId =>
  api.patch(`/ofertascompra/${ofertaCompraId}/reabrir`);
export const encerrarOferta = ofertaCompraId =>
  api.patch(`ofertascompra/${ofertaCompraId}/encerrar-sessao`);
export const deletar = ofertaCompraId => api.delete(`/ofertascompra/${ofertaCompraId}`);

export const getResponsaveis = ofertaCompraId =>
  api.get(`/ofertascompra/${ofertaCompraId}/responsaveis`);
export const criarResponsavel = (ofertaCompraId, dados) =>
  api.post(`/ofertascompra/${ofertaCompraId}/responsaveis`, dados);
export const deletarResponsavel = (ofertaCompraId, responsavelId) =>
  api.delete(`/ofertascompra/${ofertaCompraId}/responsaveis/${responsavelId}`);

export const getPrazos = ofertaCompraId => api.get(`/ofertascompra/${ofertaCompraId}/prazos`);

export const getItens = (ofertaCompraId, listarItensLote = false, v2 = false) => {
  const params = `?listarItensLote=${listarItensLote}&v2=${v2}`;
  return api.get(`/ofertascompra/${ofertaCompraId}/itens${params}`);
};

export const getItem = (ofertaCompraId, itemId) =>
  api.get(`/ofertascompra/${ofertaCompraId}/itens/${itemId}`);

export const atualizarItem = (ofertaCompraId, itemId, dados) =>
  api.patch(`/ofertascompra/${ofertaCompraId}/itens/${itemId}`, dados);

export const revogarItem = (ofertaCompraId, itemId, dados) =>
  api.patch(`/ofertascompra/${ofertaCompraId}/itens/${itemId}/revogar`, dados);
export const deletarItem = (ofertaCompraId, ofertaCompraItemId) =>
  api.delete(`/ofertascompra/${ofertaCompraId}/itens/${ofertaCompraItemId}`);
export const deletarLoteItem = (ofertaCompraId, ofertaCompraItemId, ofertaCompraItemLoteId) => {
  return api.delete(
    `/ofertascompra/${ofertaCompraId}/itens/${ofertaCompraItemId}/itens-lote/${ofertaCompraItemLoteId}`,
  );
};

export const publishAuction = auctionId => api.get(`/ofertascompra/${auctionId}/publicar`);
export const openAuctionSession = auctionId => api.get(`/ofertascompra/${auctionId}/abrir-sessao`);

export const getLances = (ofertaCompraId, tipo) =>
  api.get(`/ofertascompra/${ofertaCompraId}/lances/?filtro=${tipo}`);
export const getLancesItem = (ofertaCompraId, itemId) =>
  api.get(`/ofertascompra/${ofertaCompraId}/itens/${itemId}/lances`);
export const getOfertasItem = (ofertaCompraId, itemId) =>
  api.get(`/ofertascompra/${ofertaCompraId}/itens/${itemId}/ofertas`);
export const iniciarLance = (ofertaCompraId, lances) =>
  api.post(`/ofertascompra/${ofertaCompraId}/itens/lances`, lances);
export const enviarLance = (ofertaCompraId, lances) =>
  api.post(`/ofertascompra/${ofertaCompraId}/lances`, lances);
export const getElegiveisFechado = ofertaCompraId =>
  api.get(`/ofertascompra/${ofertaCompraId}/elegiveis-fechado`);
export const getParticipantesFechado = ofertaCompraId =>
  api.get(`/ofertascompra/${ofertaCompraId}/participantes-fechado`);

export const iniciarPreferencia = (ofertaCompraId, itens) =>
  api.post(`/ofertascompra/${ofertaCompraId}/itens/iniciar/preferencia`, itens);
export const enviarPreferencia = (ofertaCompraId, preferencias) =>
  api.post(`/ofertascompra/${ofertaCompraId}/preferencias`, preferencias);

export const getAnexos = ofertaCompraId => api.get(`/ofertascompra/${ofertaCompraId}/anexos`);
export const criarAnexos = (
  ofertaCompraId,
  dados,
  { onUploadProgress } = { onUploadProgress: () => {} },
) => api.post(`/ofertascompra/${ofertaCompraId}/anexos`, dados, { onUploadProgress });
export const deletarAnexo = (ofertaCompraId, id) =>
  api.delete(`/ofertascompra/${ofertaCompraId}/anexos/${id}`);

export const aceitarLanceNegociacao = (ofertaCompraId, itemId, dados) =>
  api.post(`/ofertascompra/${ofertaCompraId}/negociacao/${itemId}/aceitar/`, dados);
export const enviarLanceNegociacao = (ofertaCompraId, propostas) =>
  api.post(`/ofertascompra/${ofertaCompraId}/negociacao/enviar/`, propostas);
export const recusarLanceNegociacao = (ofertaCompraId, itemId, dados) =>
  api.post(`/ofertascompra/${ofertaCompraId}/negociacao/${itemId}/recusar/`, dados);

export const fetchChatMessages = auctionId => api.get(`/ofertasCompra/${auctionId}/chat`);
export const fetchChatParticipants = auctionId =>
  api.get(`/ofertasCompra/${auctionId}/participants`);

export const getRecursos = ofertaCompraId => api.get(`/ofertascompra/${ofertaCompraId}/recursos`);
export const criarRecurso = (ofertaCompraId, dados) =>
  api.post(`/ofertascompra/${ofertaCompraId}/recursos`, dados);
export const atualizarRecurso = (ofertaCompraId, recursoId, dados) =>
  api.patch(`/ofertascompra/${ofertaCompraId}/recursos/${recursoId}`, dados);

export const exportAccountIntegration = ofertaCompraId =>
  api.get(`/auctions/${ofertaCompraId}/accounting-integration`);

export const exportPublicenterIntegration = ofertaCompraId =>
  api.get(`/auctions/${ofertaCompraId}/publicenter-integration`);

export const buscarOfertaAdesaoEmpresas = ofertaCompraId =>
  api.get(`/ofertasCompra/${ofertaCompraId}/adesao-empresas`);

export const adjudicarVencedorItens = (ofertaCompraId, data) =>
  api.post(`/auctions/${ofertaCompraId}/items/adjudication`, data);

export const homologarVencedorItens = (ofertaCompraId, data) =>
  api.post(`/auctions/${ofertaCompraId}/items/homologation`, data);

export const deletarProposta = (ofertaCompraId, itemId, proposalId) =>
  api.delete(`/auctions/${ofertaCompraId}/items/${itemId}/proposals/${proposalId}`);

export const deletarLance = (ofertaCompraId, itemId, lanceId) =>
  api.delete(`/auctions/${ofertaCompraId}/items/${itemId}/bids/${lanceId}`);

export const excluirUltimoLance = item =>
  api.delete(`/auctions/${item.ofertaCompraId}/items/${item.itemId}/last-bid`);

export const generateAuctionMinutes = (auctionId, data) =>
  api.patch(`/ofertascompra/${auctionId}/gerar-ata`, data);
