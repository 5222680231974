<template>
  <div class="buyer-indexed-container">
    <h2 class="title is-4">Cadastradas</h2>

    <div v-if="!data.length" class="panel">
      <div class="panel-block is-justify-content-center">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nenhuma licitação.</p>
          </div>
        </section>
      </div>
    </div>

    <div v-if="data.length" class="panel">
      <div class="panel-block">
        <b-table
          class="table is-fullwidth table-typography-size"
          :data="data"
          :loading="tableLoading"
        >
          <b-table-column sortable field="type" label="Tipo" v-slot="props">
            <b-tooltip
              :label="props.row.type === 'dispensation' ? 'Dispensa' : 'Pregão'"
              :position="mobile ? 'is-left' : 'is-top'"
            >
              <b-icon
                class="custom-icon"
                type="is-primary"
                :icon="props.row.type === 'dispensation' ? 'alpha-d-box' : 'alpha-p-box'"
              ></b-icon>
            </b-tooltip>
          </b-table-column>

          <b-table-column
            centered
            sortable
            field="participation"
            label="Exclusiva"
            :visible="displayExclusiveColumn"
            v-slot="props"
          >
            <b-icon
              icon="check"
              size="is-medium"
              type="is-success"
              v-show="displayExclusiveIcon(props.row)"
            />
          </b-table-column>

          <b-table-column centered sortable field="biddingNumber" label="Licitação" v-slot="props">
            <router-link
              v-if="props.row.situationId > 1"
              :to="{
                name: props.row.type === 'dispensation' ? 'Dispensa' : 'Pregao',
                params: { id: props.row.id },
              }"
            >
              {{ props.row.biddingNumber }}
            </router-link>
            <p v-else>{{ props.row.biddingNumber }}</p>
          </b-table-column>

          <b-table-column
            centered
            sortable
            field="openDate"
            label="Previsão de Abertura"
            v-slot="props"
            :visible="!tableLoading && data.some(row => row.openDate)"
          >
            <div v-if="props.row.situationId === 17 && props.row.reopenDate">
              {{ props.row.reopenDate | formatDate }}
            </div>

            <div v-else>{{ props.row.openDate | formatDate }}</div>
          </b-table-column>

          <b-table-column
            centered
            label="Opções"
            v-slot="props"
            :visible="company.buyer && (operateAuctionPermission || operateDispensationPermission)"
          >
            <b-field id="buyer-indexed-options">
              <p class="control">
                <b-tooltip label="Editar">
                  <button
                    class="button is-primary"
                    ref="editar"
                    v-if="company.id === props.row.buyerId && props.row.situationId < 4"
                    @click.prevent.stop="editBidding(props.row.id, props.row.type)"
                  >
                    <b-icon icon="pencil" size="is-small" />
                  </button>
                </b-tooltip>
              </p>
              <p class="control">
                <b-tooltip label="Excluir" type="is-danger">
                  <button
                    class="button is-danger"
                    ref="excluir"
                    v-if="company.id === props.row.buyerId && props.row.situationId === 1"
                    @click.prevent.stop="deleteBidding(props.row.id, props.row.type)"
                  >
                    <b-icon icon="delete" size="is-small" />
                  </button>
                </b-tooltip>
              </p>
            </b-field>
          </b-table-column>

          <template v-slot:footer>
            <AppPagination
              :data-filters="{
                page: filters.page,
                perPage: filters.perPage,
              }"
              :isStateURLEnabled="false"
              :number-lines="10"
              :total="count"
              @change-page="page => changePage(page)"
              @change-per-page="perPage => changePerPage(perPage)"
            />
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AppPagination from '@/components/AppPagination';
import { formatarData as formatDate } from '@/utils/format';

export default {
  name: 'BuyerIndexedBiddings',
  components: {
    AppPagination,
  },
  props: {
    count: { type: Number, default: 0 },
    data: { type: Array, default: () => [] },
    mobile: { type: Boolean, default: false },
    operateAuctionPermission: { type: Boolean, default: false },
    operateDispensationPermission: { type: Boolean, default: false },
    tableLoading: { type: Boolean, default: false },
  },
  filters: {
    formatDate,
  },
  data() {
    const filters = {
      page: 1,
      perPage: 10,
    };

    const loading = {
      general: false,
    };
    return {
      filters,
      loading,
    };
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      user: 'getUser',
    }),
    displayExclusiveColumn() {
      return this.data.some(
        row =>
          row.participation === 'AMPLOCOTA' ||
          row.participation === 'RESERVADO' ||
          row.participation === 'EXCLUSIVA',
      );
    },
  },
  methods: {
    ...mapActions(['excluirDispensa', 'excluirOfertaCompra']),
    changePage(page) {
      this.filters.page = page;
      this.$emit('filter-buyer-indexed-biddings', {
        page: this.filters.page,
        perPage: this.filters.perPage,
      });
    },
    changePerPage(perPage) {
      this.filters.page = Number(perPage) > Number(this.filters.perPage) ? 1 : this.filters.page;
      this.filters.perPage = perPage;
      this.$emit('filter-buyer-indexed-biddings', {
        page: this.filters.page,
        perPage: this.filters.perPage,
      });
    },
    async deleteBidding(id, type) {
      const confirmation = await this.$confirmacao({
        message: 'Tem certeza que deseja excluir a licitação?',
      });
      if (!confirmation) {
        return;
      }

      this.loading.general = true;
      try {
        if (type === 'dispensation') {
          await this.excluirDispensa(id);
        } else {
          await this.excluirOfertaCompra(id);
        }
        this.$parent.deleteBidding(id, type);
      } catch (error) {
        this.$alerta('Erro ao excluir licitação', 'is-danger');
      } finally {
        this.loading.general = false;
      }
    },
    displayExclusiveIcon(row) {
      return (
        row.participation === 'AMPLOCOTA' ||
        row.participation === 'RESERVADO' ||
        row.participation === 'EXCLUSIVA'
      );
    },
    async editBidding(id, type) {
      if (type === 'dispensation') {
        this.$router.push({ name: 'DispensaEditar', params: { id } });
      } else {
        this.$router.push({ name: 'PregaoEditar', params: { id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buyer-column {
  min-width: 150px;
}

.buyer-indexed-container {
  margin-right: 10px;
  width: 50%;
}

@media (max-width: 1160px) {
  .buyer-indexed-container {
    width: 100%;
  }
}
</style>

<style lang="scss">
#buyer-indexed-options .field-body .field {
  justify-content: center;
}
</style>
