<template>
  <div>
    <b-loading is-full-page :active="loading.fetchAuction" can-cancel></b-loading>

    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container is-fluid">
          <div class="level is-flex-touch column-direction-touch">
            <div class="level-left level-column margin-1">
              <div class="level-item">
                <div>
                  <h1 class="title">Pregão Eletrônico</h1>
                  <br />
                  <div class="auction-info">
                    <ul>
                      <li v-if="auction.comprador.nome">
                        <strong class="has-text-white">Comprador:</strong>
                        {{
                          auction.comprador && auction.comprador.nome
                            ? capitalize(auction.comprador.nome)
                            : ''
                        }}
                      </li>

                      <li>
                        <strong class="has-text-white">Número:</strong>
                        {{ auction.numero_oc }}
                      </li>

                      <li>
                        <strong class="has-text-white">Tipo:</strong>
                        {{
                          auction.tipo_pregao === 'IMEDIATO'
                            ? 'Contratação Imediata'
                            : 'Registro de Preços'
                        }}
                      </li>

                      <li>
                        <strong class="has-text-white">Modo de Disputa:</strong>
                        {{ auction.modo_disputa === 'ABERTO' ? 'Aberto' : 'Aberto/Fechado' }}
                      </li>

                      <li>
                        <strong class="has-text-white">Negociação:</strong>
                        {{ auction.tipo_negociacao.nome }}
                      </li>

                      <li>
                        <strong class="has-text-white">Tipo de Edital:</strong>
                        {{ noticeType }}
                      </li>

                      <li v-if="reservedNotice">
                        <strong class="has-text-white">Preferência Local/Regional:</strong>
                        {{ auction.preferencia_regional ? 'Sim' : 'Não' }}
                      </li>

                      <li v-if="!auction.tipo_negociacao.id === 3">
                        <strong class="has-text-white">Divulgação de Referencial:</strong>
                        {{ auction.divulgar_referencial ? 'Sim' : 'Não' }}
                      </li>

                      <li>
                        <strong class="has-text-white">Origem dos Recursos:</strong>
                        {{ auction.origem_recursos.join(', ') }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="level-item has-text-centered level-column margin-1"
              v-if="verifySituation([1, 2, 3, 4])"
            >
              <div>
                <p class="heading">Previsão de Abertura</p>
                <p class="title">{{ auction.data_abertura | formatDate }}</p>
              </div>
            </div>

            <div
              class="level-item has-text-centered level-column margin-1"
              v-else-if="auction.data_reabertura"
            >
              <div>
                <p class="heading">Previsão de Reabertura</p>
                <p class="title">{{ auction.data_reabertura | formatDate }}</p>
              </div>
            </div>

            <div
              class="level-item has-text-centered level-column margin-1"
              v-if="isBidder && situation >= 5"
            >
              <div>
                <p class="heading">Apelido</p>
                <p class="title">{{ bidderAlias }}</p>

                <p
                  v-if="situation === 6 && randomTime && isBidder"
                  class="title is-3 has-text-warning imminent-closure-text"
                >
                  Encerramento Iminente
                </p>
              </div>
            </div>

            <div v-if="verifySituation([6, 7, 11]) && !randomTime" class="level-column margin-1">
              <App-Timer />
            </div>

            <div
              class="level-item has-text-centered level-column margin-1"
              v-if="situation === 6 && !isBidder && randomTime"
            >
              <div>
                <p class="title is-3 has-text-warning">
                  Encerramento Iminente
                </p>
              </div>
            </div>

            <div class="level-right level-column margin-1">
              <div class="level-item has-text-centered level-column">
                <div>
                  <p class="heading">Situação</p>
                  <p class="title">{{ situationName(auction.situacao.nome) }}</p>

                  <b-button
                    inverted
                    outlined
                    v-if="loaded && activeAuction && provider"
                    class="level-button"
                    icon-left="comment-eye-outline"
                    :type="auctionWatch.id ? 'is-danger' : 'is-success'"
                    :loading="loading.watchAuction"
                    @click.prevent.stop="watchElectronicAuction()"
                  >
                    {{ auctionWatch.id ? 'Não Assistir' : 'Assistir' }}
                  </b-button>

                  <button
                    v-if="displayOpenSession"
                    class="button is-primary is-inverted is-outlined level-button"
                    :class="{ ' is-loading': loading.startSession }"
                    @click.prevent.stop="startSession"
                  >
                    Abrir Sessão
                  </button>

                  <button
                    class="button is-primary is-inverted is-outlined level-button"
                    :disabled="disable.getMinutes"
                    v-if="renderGetMinutes"
                    @click.prevent.stop="openConsiderationModal"
                  >
                    Obter Ata
                  </button>

                  <button
                    class="button is-primary is-inverted is-outlined level-button"
                    :disabled="disable.getMinutes"
                    v-if="renderGetAccountIntegration"
                    @click.prevent.stop="getAccountIntegration"
                  >
                    Exportar Integração
                  </button>

                  <button
                    v-if="displayEndAnalysis"
                    class="button is-primary is-inverted is-outlined level-button"
                    :class="{ ' is-loading': loading.general }"
                    :disabled="analyzedProposals"
                    @click.prevent.stop="finishProposalsAnalysis()"
                  >
                    Encerrar Análise
                  </button>

                  <button
                    v-if="displayFinishBids && !itemsForBids"
                    class="button is-primary is-inverted is-outlined level-button"
                    :class="{ ' is-loading': loading.general }"
                    @click.prevent.stop="finishBids()"
                  >
                    Encerrar Lances
                  </button>

                  <button
                    v-if="situation === 10 && isCrier"
                    :disabled="disableFinishSession"
                    class="button is-primary is-inverted is-outlined level-button"
                    :class="{ ' is-loading': loading.finishSession }"
                    @click.prevent.stop="finishSession(auction.id)"
                  >
                    Encerrar Sessão
                  </button>

                  <button
                    v-if="displaySuspend"
                    class="action-button button is-primary is-inverted is-outlined level-button"
                    :class="{ ' is-loading': loading.suspendSession }"
                    @click.prevent.stop="openModal(auction.id, 'suspend')"
                  >
                    Suspender
                  </button>

                  <button
                    v-if="displayBackToProposals"
                    class="action-button button is-primary is-inverted is-outlined level-button"
                    :class="{ ' is-loading': loading.suspendSession }"
                    @click.prevent.stop="goBackToProposals()"
                  >
                    Voltar Para Propostas
                  </button>

                  <b-button
                    v-if="
                      isCrier &&
                        situation === 17 &&
                        auction.suspensao_automatica &&
                        !auction.data_reabertura
                    "
                    class="action-button button is-primary is-inverted is-outlined level-button"
                    :class="{ ' is-loading': loading.general }"
                    @click.prevent.stop="openModal(auction.id, 'insertReopeningDate')"
                  >
                    Inserir Reabertura
                  </b-button>

                  <button
                    v-if="isCrier && situation === 17 && auction.data_reabertura"
                    class="action-button button is-primary is-inverted is-outlined level-button"
                    :class="{ ' is-loading': loading.general }"
                    @click.prevent.stop="reopenSession(auction.id)"
                  >
                    Reabrir Sessão
                  </button>

                  <button
                    v-if="displayCancelOrRevokeAuction"
                    class="button is-primary is-inverted is-outlined level-button"
                    :class="{ ' is-loading': loading.revoke }"
                    @click.prevent.stop="openModal(auction.id, 'revoke')"
                  >
                    Revogar
                  </button>

                  <button
                    v-if="displayCancelOrRevokeAuction"
                    class="button is-primary is-inverted is-outlined level-button"
                    :class="{ ' is-loading': loading.cancel }"
                    @click.prevent.stop="openModal(auction.id, 'cancel')"
                  >
                    Anular
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="displayPendingWarning" class="columns">
            <div class="column has-text-centered">
              <b-icon
                class="icon-pending-company"
                type="is-warning"
                icon="alert-box"
                size="is-large"
              />
              <span class="text-pending-company">
                Empresa pendente de aprovação da SelCorp. Caso não tenha solicitado validação, por
                favor solicite no perfil de sua empresa.
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="needReload && loaded && !logging" class="disconnection-content">
      <b-notification
        indefinite
        class="disconnection-notification"
        role="alert"
        type="is-warning"
        :closable="false"
      >
        <b-icon class="notification-icon" icon="alert-box" size="is-medium" />
        <span class="notification-text"
          >O navegador perdeu a conexão com o servidor. Por favor, recarregue a página ou clique
          <a class="notification-link" @click.stop.prevent="reloadPage()">aqui</a> para
          recarregar.</span
        >
      </b-notification>
    </div>

    <div class="limit">
      <div class="auction-container">
        <section>
          <b-tabs
            expanded
            destroy-on-hide
            ref="tabs"
            type="is-toggle"
            :animated="false"
            v-model="selectedTab"
          >
            <b-tab-item label="Comprador">
              <Auction-Buyer class="components" />
            </b-tab-item>

            <b-tab-item label="Edital/Anexos">
              <Auction-Attachments class="components" />
            </b-tab-item>

            <b-tab-item label="Itens">
              <Send-Documents
                class="components"
                v-if="display.sendDocuments"
                :items="items"
                :item="item"
                :qualificationCompany="qualificationCompany"
              />
              <Auction-Offers
                class="components"
                v-if="display.offers"
                :item="item"
                :oferta-compra-id="auctionId"
              />
              <Auction-Items class="components" ref="items" v-if="display.items" :loaded="loaded" />
            </b-tab-item>

            <b-tab-item :disabled="!loaded" v-if="displayDocumentsTab">
              <template slot="header"
                ><b-icon
                  v-if="proposalsDelivery"
                  class="custom-icon"
                  :icon="requiredDocumentsSent ? 'check' : 'close'"
                  :type="requiredDocumentsSent ? 'is-success' : 'is-danger'"
                ></b-icon
                >{{ proposalsDelivery ? 'Documentos' : 'Documentos Aceitos' }}</template
              >

              <Send-Documents class="components" v-if="proposalsDelivery" />
              <Documents-List v-else class="components" />
            </b-tab-item>

            <b-tab-item label="Esclarecimentos/Impugnações">
              <Clarifications-Impugnations-Wrapper
                class="components"
                :internal-tab-selected="internalTabSelected"
              />
            </b-tab-item>

            <b-tab-item label="Gestão de Prazos">
              <Auction-Deadlines class="components" />
            </b-tab-item>

            <b-tab-item label="Ata" v-if="verifySituation([12, 13, 14, 15, 16]) && auction.hasAta">
              <object height="1000px" type="application/pdf" width="100%" :data="linkPdf">
                <div class="minutes-pdf-download">
                  <p class="minutes-pdf-download-text">O navegador não suporta PDF.</p>

                  <a
                    download
                    class="button is-primary is-medium"
                    :href="fileServer(getMinutesPdfName)"
                    >Download Ata</a
                  >
                </div>
              </object>
            </b-tab-item>

            <b-tab-item
              label="Termos"
              v-if="
                verifySituation([15, 16]) &&
                  (auction.hasTermoAdjudicacao || auction.hasTermoHomologacao)
              "
            >
              <AuctionTermsWrapper
                class="components"
                :internal-tab-selected="internalTabSelected"
              />
            </b-tab-item>

            <b-tab-item v-if="displayAppealsTab" label="Recursos">
              <Auction-Appeals class="components" />
            </b-tab-item>

            <b-tab-item v-if="situation > 11 && situation < 17" label="Atos Decisórios">
              <Decision-Acts class="components" />
            </b-tab-item>
          </b-tabs>
        </section>
      </div>
    </div>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justification.active"
      :onCancel="resetLoadings"
      @justificate="sendJustification"
    >
      <AppJustification
        v-if="justification.active"
        :displayDateTime="justification.displayDateTime"
        :displayFailButton="justification.displayFail"
        :displaySuccessButton="justification.displaySuccess"
        :failText="justification.failText"
        :suspensionText="justification.displaySuspendText"
        :workDays="justification.displayWorkingDays"
      />
    </b-modal>

    <b-modal
      has-modal-card
      ref="sendTimeModal"
      :active.sync="reopeningDate.active"
      :onCancel="resetLoadings"
      @send-date-time="processReopeningDate"
    >
      <AppSendDateTime
        v-if="reopeningDate.active"
        :automaticSuspensionDate="reopeningDate.automaticSuspensionDate"
        :loading="reopeningDate.loading"
        :maximumDate="reopeningDate.maximumDate"
        :minimumDate="reopeningDate.minimumDate"
      />
    </b-modal>

    <b-modal
      has-modal-card
      ref="considerationModal"
      :active.sync="consideration"
      :onCancel="resetLoadings"
      @considerar="generateMinutes"
    >
      <Considerations v-if="consideration" />
    </b-modal>

    <AuctionChat v-if="situation >= 4" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Holidays from 'date-holidays';

import AppJustification from '@/components/AppJustification';
import AppSendDateTime from '@/components/AppSendDateTime';
import AppTimer from '@/components/AppTimer';
import AuctionAppeals from '@/views/Pregao/AuctionContainer/Recursos';
import AuctionAttachments from '@/views/Pregao/AuctionContainer/Edital';
import AuctionBuyer from '@/views/Pregao/AuctionContainer/Comprador';
import AuctionChat from '@/views/Pregao/AuctionContainer/AuctionChat';
import AuctionDeadlines from '@/views/Pregao/AuctionContainer/Prazos';
import AuctionItems from '@/views/Pregao/AuctionContainer/Items/ItemsWrapper';
import AuctionOffers from '@/views/Pregao/AuctionContainer/Offers';
import ClarificationsImpugnationsWrapper from '@/views/Pregao/AuctionContainer/ClarificationsImpugnations/ClarificationsImpugnationsWrapper';
import AuctionTermsWrapper from '@/views/Pregao/AuctionContainer/Terms/AuctionTermsWrapper';
import Considerations from '@/views/Pregao/AuctionContainer/Consideracao';
import DecisionActs from '@/views/Pregao/AuctionContainer/DecisionActs';
import DocumentsList from '@/views/Pregao/AuctionContainer/Documents/DocumentsList';
import SendDocuments from '@/views/Pregao/AuctionContainer/Documents/SendDocuments';

import SocketResource from '@/resources/socket';

import { capitalize, fileServer } from '@/utils/functions';
import { formatarData as formatDate } from '@/utils/format';
import { funcoes } from '@/utils/enumerados';
import sanitizeFileName from '@/utils/sanitizes';

export default {
  name: 'AuctionContainer',
  components: {
    AppJustification,
    AppSendDateTime,
    AppTimer,
    AuctionAppeals,
    AuctionAttachments,
    AuctionBuyer,
    AuctionChat,
    AuctionDeadlines,
    AuctionItems,
    AuctionOffers,
    ClarificationsImpugnationsWrapper,
    AuctionTermsWrapper,
    Considerations,
    DecisionActs,
    DocumentsList,
    SendDocuments,
  },
  data() {
    const disable = {
      getMinutes: false,
    };
    const display = {
      offers: false,
      items: true,
      sendDocuments: false,
    };
    const loading = {
      cancel: false,
      fetchAuction: false,
      finishSession: false,
      general: false,
      revoke: false,
      startSession: false,
      suspendSession: false,
      watchAuction: false,
    };
    const justification = {
      active: false,
      displayDateTime: false,
      displayFail: false,
      displaySuccess: false,
      displaySuspendText: false,
      displayBackToProposals: false,
      displayWorkingDays: false,
      failText: '',
    };
    const reopeningDate = {
      active: false,
      automaticSuspensionDate: null,
      loading: false,
      maximumDate: null,
      minimumDate: null,
    };

    return {
      action: '',
      auctionId: 0,
      capitalize,
      consideration: false,
      disable,
      display,
      fileServer,
      holidays: new Holidays('BR'),
      internalTabSelected: 0,
      item: null,
      justification,
      loaded: false,
      loading,
      needReload: false,
      qualificationCompany: null,
      reopeningDate,
      selectedTab: 2,
      socketResource: new SocketResource(this.$socket),
    };
  },
  beforeRouteLeave(to, from, next) {
    this.socketResource.leaveAuction(this.auction.id);
    document.title = 'SelCorp - Sistema Eletrônico de Licitações';
    this.limparPregao();
    this.limparAnexosPregao();
    this.limparItensPregao();
    this.limparResponsaveisPregao();
    this.cleanAuctionProposals();
    this.limparLancesPregao();
    this.cleanAuctionDeclarations();
    this.cleanAuctionDocuments();
    this.invalidatePin();
    return next();
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('auctionsClarifications', ['clarifications']),
    ...mapState('proposals', { allProposals: 'proposals' }),
    ...mapState('auctionsDocuments', ['auctionDocuments']),
    ...mapState('auctionsImpugnations', ['impugnations']),
    ...mapState('auctionsOptions', ['auctionWatch']),
    ...mapState('companies', ['company']),
    ...mapGetters('proposals', { proposals: 'obterPropostasUsuarioLogado' }),
    ...mapState('auctionsBidders', ['userLoggedBidder']),
    ...mapState('requiredDocuments', ['requiredDocuments']),
    ...mapGetters({
      appeals: 'obterOfertaCompraRecursos',
      auction: 'obterOfertaCompra',
      bids: 'obterOfertaCompraLances',
      deadlines: 'obterOfertaCompraPrazos',
      logged: 'getLogged',
      logging: 'getLogging',
      items: 'obterTodosItens',
      user: 'getUser',
      userFunction: 'getFunction',
    }),
    activeAuction() {
      return (this.situation > 1 && this.situation < 12) || this.situation === 17;
    },
    adhesionStep() {
      return this.deadlines.some(prazo => prazo.prazo_id === 13);
    },
    analyzedProposals() {
      const filtro = this.allProposals.filter(
        proposta => ['DESERTO', 'FRACASSADA', 'REVOGADO'].indexOf(proposta.item_estado) === -1,
      );
      return !filtro.every(
        proposta => proposta.estado === 'CLASSIFICADA' || proposta.estado === 'DESCLASSIFICADA',
      );
    },
    isAuthority() {
      return this.userFunction === funcoes.AUTORIDADE;
    },
    isBidder() {
      return this.userFunction === funcoes.LICITANTE;
    },
    bidderAlias() {
      return this.userLoggedBidder.alias;
    },
    isBuyer() {
      return this.company.isBuyer && this.company.id === this.auction.comprador.id;
    },
    closedMode() {
      return this.items.some(
        item => ['LANCE-FECHADO', 'PRIMEIRA-CHAMADA', 'NOVA-CHAMADA'].indexOf(item.estado) > -1,
      );
    },
    closedDisputeMode() {
      return this.auction.modo_disputa === 'FECHADO';
    },
    isCrier() {
      return this.userFunction === funcoes.PREGOEIRO;
    },
    displayAppealsTab() {
      if ([10, 11, 15, 16].indexOf(this.situation) > -1) return true;
      if ([12, 14, 17].indexOf(this.situation) > -1 && this.appeals.length) {
        return true;
      }
      return false;
    },
    displayCancelOrRevokeAuction() {
      return this.loaded && this.isAuthority && [2, 3, 4, 15, 16, 17].indexOf(this.situation) > -1;
    },
    hasRequiredDocuments() {
      return this.requiredDocuments.length > 0;
    },
    displayDocumentsTab() {
      if (!this.hasRequiredDocuments) return false;
      if (this.proposalsDelivery && (this.provider || this.isBidder)) {
        if (!this.reservedNotice) return true;
        if (this.reservedNotice && this.company.preference) return true;
      }

      if (this.situation >= 9 && this.situation !== 13 && this.qualificationDeadline) return true;

      return false;
    },
    displayEndAnalysis() {
      return (
        this.verifySituation(5) &&
        this.isCrier &&
        this.logged &&
        this.company.id === this.auction.comprador.id
      );
    },
    displayFinishBids() {
      return (
        this.verifySituation(6) &&
        this.isCrier &&
        this.logged &&
        this.company.id === this.auction.comprador.id &&
        this.auction.modo_disputa === 'ABERTO'
      );
    },
    disableFinishSession() {
      return !!(this.appeals.length && this.appeals.some(appeal => appeal.status === 'CRIADO'));
    },
    displayOpenSession() {
      if (
        this.verifySituation(4) &&
        this.isCrier &&
        this.logged &&
        this.company.id === this.auction.comprador.id &&
        this.items.some(item => item.estado === 'CADASTRADA')
      ) {
        return true;
      }
      return false;
    },
    displayPendingWarning() {
      return (
        this.proposalsDelivery &&
        ['CADASTRADA', 'INABILITADA'].indexOf(this.company.status) > -1 &&
        this.provider
      );
    },
    displayBackToProposals() {
      const existeItensEmLance = this.items.find(
        item =>
          ['LANCE', 'LANCE-RANDOMICO', 'LANCE-FECHADO', 'PREFERENCIA-INICIADA'].indexOf(
            item.estado,
          ) > -1,
      );
      if (this.isCrier && [6, 7].indexOf(this.situation) > -1 && existeItensEmLance) {
        return false;
      }
      if (this.isCrier && this.situation === 6 && this.auction.modo_disputa === 'ABERTO') {
        return true;
      }
      return false;
    },
    displaySuspend() {
      const existeItensEmLance = this.items.find(
        item =>
          ['LANCE', 'LANCE-RANDOMICO', 'LANCE-FECHADO', 'PREFERENCIA-INICIADA'].indexOf(
            item.estado,
          ) > -1,
      );
      if (this.isCrier && [6, 7].indexOf(this.situation) > -1 && existeItensEmLance) {
        return false;
      }

      if (
        this.isCrier &&
        this.situation === 11 &&
        this.items.some(item => item.estado === 'ADESAO')
      ) {
        return false;
      }

      if (this.isCrier && this.situation > 4 && this.situation < 12) {
        return true;
      }

      return false;
    },
    hasSession() {
      return this.deadlines.some(deadline => deadline.id === 7);
    },
    itemsForBids() {
      return this.items.some(item => ['CADASTRADA', 'LANCE'].indexOf(item.estado) > -1);
    },
    linkPdf() {
      const proportion = this.windowWidth / 10;
      return fileServer(
        `${this.getMinutesPdfName}#zoom=${proportion < 120 ? proportion : 120}&view=FitB`,
      );
    },
    noticeType() {
      if (this.auction.tipo_edital === 'AMPLO') {
        return 'Participação Ampla';
      }
      if (this.auction.tipo_edital === 'AMPLOCOTA') {
        return 'Quota Reservada para ME/EPP/MEI/Cooperativa c/ Preferência';
      }
      return 'Participação Exclusiva de ME/EPP/MEI/Cooperativa c/ Preferência';
    },
    getMinutesPdfName() {
      return (
        this.auction.ata ??
        sanitizeFileName(`ata-pe-${this.auction.comprador.id}-${this.auction.numero_oc}.pdf`)
      );
    },
    proposalsDelivery() {
      return this.situation === 3;
    },
    provider() {
      return this.userFunction === funcoes.FORNECEDOR;
    },
    qualificationDeadline() {
      return this.deadlines.some(deadline => deadline.prazo_id === 11);
    },
    randomTime() {
      return this.items.some(item => item.estado === 'LANCE-RANDOMICO');
    },
    renderGetMinutes() {
      return (
        !this.auction.hasAta &&
        this.isCrier &&
        (this.verifySituation([12, 13, 15, 16]) ||
          (this.verifySituation([14, 18]) && this.hasSession))
      );
    },
    renderGetAccountIntegration() {
      return (
        this.isCrier &&
        (this.verifySituation([12, 13, 15, 16]) ||
          (this.verifySituation([14, 18]) && this.hasSession))
      );
    },
    requiredDocumentsSent() {
      const requiredTypes = this.requiredDocuments.map(element => element.type);
      const insertedTypes = this.auctionDocuments.map(element => element.type);
      return requiredTypes.every(type => insertedTypes.includes(type));
    },
    reservedNotice() {
      return this.auction.tipo_edital === 'RESERVADO';
    },
    situation() {
      return this.auction.situacao.id;
    },
    socketConnection() {
      return this.$socket.connected;
    },
  },
  watch: {
    items() {
      if (this.situation === 6) {
        const relogioParado = this.items.some(
          item => ['PRIMEIRA-CHAMADA', 'NOVA-CHAMADA'].indexOf(item.estado) > -1,
        );
        if (relogioParado) {
          this.buscarOfertaCompraLances({ ofertaCompraId: this.auction.id, tipo: 'empresa' });
        }
      }

      this.$nextTick(() => {
        if (
          this.situation === 9 &&
          Object.keys(this.$refs.items.$refs).length &&
          this.$refs.items.$refs.myitems
        ) {
          this.$refs.items.$refs.myitems.verifyOverflowed();
        }
      });
    },
    situation(newSituation, oldSituation) {
      if (oldSituation === 17) {
        this.fetchChangingData(this.$route.params.id, true);
      }

      if (newSituation === 4 && this.loaded) {
        this.fetchChangingData(this.$route.params.id, true);
        this.selectedTab = 2;
      }

      if (newSituation === 5) {
        this.fetchRequiredDocuments(this.$route.params.id);

        if (this.isCrier || this.isAuthority) {
          this.fetchProposals({
            ofertaCompraId: this.auction.id,
            tipo: 'todas',
          });
        }
      }

      if (newSituation === 8 && this.auction.divulgar_referencial) {
        this.buscarItens({ ofertaCompraId: this.auction.id, v2: this.$route.query.v2 });
      }

      if (newSituation !== 9 && oldSituation !== undefined) this.displayAuctionItems();
      if (newSituation === 9) {
        this.fetchParticipants(this.auction.id);

        if (this.isCrier && this.adhesionStep) {
          this.buscarOfertaCompraLances({
            ofertaCompraId: this.auction.id,
            tipo: 'todas',
          });
        }
      }

      if (newSituation === 11 && this.isBidder) {
        this.getAuctionQualifications(this.auction.id);
        this.buscarOfertaCompraLances({ ofertaCompraId: this.auction.id, tipo: 'empresa' });
      }
    },
    socketConnection() {
      if (this.loaded) {
        if (!this.socketConnection) {
          this.needReload = true;
          this.reconnectSocket();
        } else if (this.socketConnection) {
          this.fetchChangingData(this.$route.params.id, true);
          this.needReload = false;
        }
      }
    },
  },
  sockets: {
    'auction:items-after-remove': function updateItemsAfterRemove(items) {
      this.updateItemsAfterRemove(items);
    },
    'ofertacompra:itens': function atualizarOfertaCompraItens(items) {
      this.atualizarItens(items);
    },
    'ofertacompra:itens-estado': function atualizarOfertaCompraItensEstado(items) {
      this.atualizarItensEstado(items);
    },
    'ofertacompra:atualizar': function atualizarOfertaCompra(auction) {
      this.atualizarOfertaCompra(auction);
    },
    'ofertacompra:proposta-adicionar': function adicionarProposta(proposal) {
      this.AUCTION_PROPOSALS(proposal);
    },
    'ofertacompra:proposta-excluir': function excluirProposta(proposal) {
      this.AUCTION_PROPOSALS_REMOVE(proposal);
    },
    'ofertacompra:lance-excluir': function excluirLances(lances) {
      this.OFERTA_COMPRA_LANCES_DELETAR(lances);
    },
    'ofertacompra:proposta': async function atualizarOfertaCompraProposta(data) {
      if (this.proposalsDelivery && this.isBuyer) {
        this.updateProposalForBuyer(data);
      } else if (this.situation === 5 && this.isBidder) {
        this.updateProposalForBidder(data);
      }
      this.updateAuctionBidsClassification(data);
    },
    'ofertacompra:lances-empresa': function atualizarOfertaCompraLancesEmpresa(bids) {
      if (bids.length && this.isBidder && this.bids[0].fornecedor_id === bids[0].fornecedor_id) {
        this.atualizarLancesAdesaoEmpresa(bids);
      }
    },
    'ofertacompra:elegiveis-fechado': function atualizarDadosElegiveisFechado(dados) {
      this.atualizarElegiveisFechado(dados);
    },
    'ofertacompra:participantes-fechado': function atualizarDadosParticipantesFechado(dados) {
      this.atualizarParticipantesFechado(dados);
    },
    'ofertacompra:prazos': function atualizarOfertaCompraPrazos(deadlines) {
      this.atualizarPrazos(deadlines);
    },
    'ofertacompra:recursos': function atualizarOfertaCompraRecursos(appeals) {
      this.atualizarRecursos(appeals);
    },
  },
  filters: {
    formatDate,
  },
  methods: {
    ...mapActions('auctionsClarifications', ['getAuctionClarifications']),
    ...mapActions('auctionsDeclarations', ['cleanAuctionDeclarations', 'searchDeclarations']),
    ...mapActions('auctionsDocuments', ['fetchAuctionBidderDocuments', 'cleanAuctionDocuments']),
    ...mapActions('auctionsImpugnations', ['getAuctionImpugnations']),
    ...mapActions('auctionsOptions', ['fetchAuctionWatch', 'updateAuctionWatch']),
    ...mapActions('auctionsQualifications', ['getAuctionQualifications']),
    ...mapActions('auctionsBidders', ['fetchUserLoggedBidder']),
    ...mapActions('chat', ['fetchMessages', 'fetchParticipants']),
    ...mapActions('companiesDocuments', ['fetchCompanyDocuments']),
    ...mapActions('documentTypes', ['fetchDocumentTypes']),
    ...mapActions('requiredDocuments', ['fetchRequiredDocuments']),
    ...mapActions('proposals', [
      'cleanAuctionProposals',
      'fetchProposals',
      'updateProposalForBidder',
      'updateProposalForBuyer',
      'removeProposal',
    ]),
    ...mapActions([
      'atualizarItens',
      'atualizarItensEstado',
      'atualizarOfertaCompra',
      'atualizarPrazos',
      'atualizarElegiveisFechado',
      'atualizarParticipantesFechado',
      'atualizarLancesAdesaoEmpresa',
      'atualizarRecursos',
      'buscarOfertaCompra',
      'buscarItens',
      'buscarOfertaCompraPrazos',
      'buscarOfertaCompraAnexos',
      'buscarOfertaCompraResponsaveis',
      'buscarOfertaCompraLances',
      'buscarOfertaCompraElegiveisFechado',
      'buscarOfertaCompraParticipantesFechado',
      'buscarOfertaCompraRecursos',
      'buscarAdesoesPregao',
      'cancelAuction',
      'encerrarAnalisePropostas',
      'encerrarEtapaLances',
      'voltarParaAnaliseDePropostas',
      'encerrarPregao',
      'generateAuctionMinutes',
      'invalidatePin',
      'limparPregao',
      'limparAnexosPregao',
      'limparItensPregao',
      'limparResponsaveisPregao',
      'limparLancesPregao',
      'openAuctionSession',
      'reabrirOfertaCompra',
      'revokeAuction',
      'suspendAuction',
      'updateAuctionBidsClassification',
      'updateItemsAfterRemove',
      'updateReopeningDate',
      'exportAccountIntegration',
      'exportPublicenterIntegration',
    ]),
    ...mapMutations('proposals', ['AUCTION_PROPOSALS_REMOVE', 'AUCTION_PROPOSALS']),
    ...mapMutations(['OFERTA_COMPRA_LANCES_DELETAR']),
    async cancelElectronicAuction(dados) {
      this.loading.revoke = true;
      try {
        await this.cancelAuction(dados);
        this.$alerta('Pregão eletrônico anulado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao anular pregão eletrônico', 'is-danger');
      }
      this.loading.revoke = false;
      this.$refs.justificationModal.close();
    },
    displayDetails({ tipo, item, ofertaCompraId }) {
      this.auctionId = ofertaCompraId;
      this.item = item;
      if (tipo === 'oferta') {
        this.display.items = false;
        this.display.offers = true;
      }
    },
    displaySendDocuments({ item, company }) {
      this.display.items = false;
      this.display.sendDocuments = true;
      this.item = item;
      this.qualificationCompany = company;
    },
    displayAuctionItems() {
      this.display.items = true;
      this.display.offers = false;
      this.display.sendDocuments = false;
      this.buscarItens({ ofertaCompraId: this.auction.id, v2: this.$route.query.v2 });
    },
    reconnectSocket() {
      this.socketResource.closeConnection();
      this.socketResource.openConnection();
    },
    enterAuctionRoom() {
      if (!this.logging) {
        this.socketResource.enterAuction(this.auction.id, this.user.id, this.company.id);
      }
    },
    async finishProposalsAnalysis() {
      this.loading.general = true;
      try {
        await this.encerrarAnalisePropostas();
        this.$alerta('Análise de propostas encerrada', 'is-success');
        this.selectedTab = this.$refs.tabs.activeItem.index;
      } catch (error) {
        this.$alerta('Erro ao encerrar análise de propostas', 'is-danger');
      }
      this.loading.general = false;
    },
    async getAccountIntegration() {
      this.loading.exportAccountIntegration = true;
      try {
        await this.exportAccountIntegration(this.$route.params.id);
      } catch (error) {
        this.$alerta('Erro ao exportar arquivo', 'is-danger');
      }
      this.loading.exportAccountIntegration = false;
    },
    async getPublicenterIntegration() {
      this.loading.exportPublicenterIntegration = true;
      try {
        await this.exportPublicenterIntegration(this.$route.params.id);
      } catch (error) {
        this.$alerta('Erro ao exportar arquivo', 'is-danger');
      }
      this.loading.exportPublicenterIntegration = false;
    },
    async finishBids() {
      const confirmation = await this.$confirmacao({
        message: 'Essa ação irá encerrar a etapa de Lances. Deseja prosseguir?',
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-info',
        hasIcon: true,
      });
      if (!confirmation) {
        return;
      }

      this.loading.general = true;
      try {
        await this.encerrarEtapaLances();
        this.$alerta('Etapa de lances encerrada', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao encerrar etapa de lances', 'is-danger');
      }
      this.loading.general = false;
    },
    async goBackToProposals() {
      const confirmation = await this.$confirmacao({
        message: 'Essa ação irá voltar para a etapa de Análise de Propostas. Deseja prosseguir?',
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-info',
        hasIcon: true,
      });
      if (!confirmation) {
        return;
      }

      this.loading.general = true;
      try {
        await this.voltarParaAnaliseDePropostas();
        this.$alerta('Reiniciada Análise de Propostas', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao voltar para Análise de Propostas', 'is-danger');
      }
      this.loading.general = false;
    },
    async finishSession(auctionId) {
      const confirmation = await this.$confirmacao({
        message: 'Essa ação irá encerrar o pregão eletrônico. Deseja prosseguir?',
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-warning',
        hasIcon: true,
      });
      if (!confirmation) {
        return;
      }

      this.loading.finishSession = true;
      try {
        await this.encerrarPregao(auctionId);

        this.$alerta('Pregão eletrônico encerrado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao encerrar pregão eletrônico', 'is-danger');
      }
      this.loading.finishSession = false;
    },
    async fetchAuction(auctionId) {
      this.loading.fetchAuction = true;
      try {
        await this.buscarOfertaCompra(auctionId);
      } catch (error) {
        this.$router.push({ name: 'PaginaInexistente' });
      }

      await this.buscarOfertaCompraResponsaveis(auctionId);
      await this.fetchChangingData(auctionId);

      if (this.activeAuction && (this.provider || this.isBidder)) {
        await this.fetchAuctionWatch({ auctionId });
      }

      Promise.all([
        this.getAuctionClarifications(auctionId),
        this.getAuctionImpugnations(auctionId),
        this.buscarOfertaCompraAnexos(auctionId),
      ]);

      this.selectTab(this.$route);
      this.loading.fetchAuction = false;
    },
    async fetchChangingData(auctionId, reloadAuction = false) {
      if (reloadAuction) {
        await Promise.all([this.buscarOfertaCompra(auctionId), this.fetchMessages(auctionId)]);
        await this.enterAuctionRoom();
        setTimeout(() => {
          this.fetchParticipants(auctionId);
        }, 100);
      }
      await Promise.all([
        this.buscarItens({ ofertaCompraId: auctionId, v2: this.$route.query.v2 }),
        this.buscarOfertaCompraPrazos(auctionId),
      ]);

      if ([3, 4].indexOf(this.situation) > -1 && (this.provider || this.isBidder)) {
        await Promise.all([
          this.searchDeclarations({
            auctionId: this.auction.id,
            companyId: this.company.id,
          }),
          this.fetchRequiredDocuments(auctionId),
        ]);
      }

      if (this.situation >= 5) {
        await this.fetchRequiredDocuments(auctionId);
      }
      if (this.situation >= 3) {
        await this.fetchProposals({
          ofertaCompraId: auctionId,
          tipo: 'todas',
        });
      }

      if (this.situation >= 6) {
        if (this.situation === 6 && this.closedMode) {
          await this.buscarOfertaCompraElegiveisFechado();
        }

        if (this.isCrier) {
          await this.buscarOfertaCompraLances({
            ofertaCompraId: auctionId,
            tipo: 'todas',
          });
        }

        if (this.isBidder) {
          await this.buscarOfertaCompraLances({
            ofertaCompraId: auctionId,
            tipo: 'empresa',
          });
        }
      }

      if ([8, 9].indexOf(this.situation) > -1 && this.closedDisputeMode) {
        await this.buscarOfertaCompraParticipantesFechado();
      }
      if (this.auction.tipo_pregao === 'REGISTRO' && this.situation >= 9 && this.isBidder) {
        await this.getAuctionQualifications(auctionId);
      }
      if (this.situation === 11 && this.company.id === this.auction.comprador.id) {
        await this.buscarAdesoesPregao();
      }
      if ([10, 11, 12, 14, 15, 16, 17].indexOf(this.situation) > -1) {
        await this.buscarOfertaCompraRecursos(auctionId);
      }
    },
    async generateMinutes(considerations) {
      this.disable.getMinutes = true;
      try {
        await this.generateAuctionMinutes({
          consideracoes: considerations,
        });
        this.$alerta('Ata obtida com sucesso', 'is-success');
        setTimeout(() => {
          if (this.$refs.tabs.items.length > 7) {
            this.selectedTab = 6;
          } else {
            this.selectedTab = 5;
          }
        }, 100);
      } catch (error) {
        this.$alerta('Erro ao obter ata', 'is-danger');
      }
      this.disable.getMinutes = false;
      this.$refs.considerationModal.close();
    },
    getWorkDay(suspensionDate, days, minimum = false) {
      let date = new Date(suspensionDate);
      date.setDate(date.getDate() + days);

      while (
        [0, 6].indexOf(date.getDay()) > -1 ||
        (this.holidays.isHoliday(date) && this.holidays.isHoliday(date).type === 'public')
      ) {
        const holiday = this.holidays.isHoliday(date);
        const day = date.getDay();
        if (day === 0 || holiday) {
          date.setDate(date.getDate() + 1);
          if (minimum) {
            date = new Date(date.setHours(0, 0, 0, 0));
          }
        } else if (day === 6) {
          date.setDate(date.getDate() + 2);
          if (minimum) {
            date = new Date(date.setHours(0, 0, 0, 0));
          }
        }
      }

      return new Date(date);
    },
    openModal(auctionId, action) {
      this.action = action;
      this.auctionId = auctionId;

      if (action === 'insertReopeningDate') {
        this.reopeningDate.active = true;

        const lastSuspension = this.deadlines
          .slice()
          .reverse()
          .find(deadline => deadline.nome === 'Suspensão');
        const minimumDate = this.getWorkDay(lastSuspension.data_de, 1, true);
        const maximumDate = this.getWorkDay(lastSuspension.data_de, 60, false);

        this.reopeningDate.automaticSuspensionDate = new Date(lastSuspension.data_de);
        this.reopeningDate.maximumDate = new Date(maximumDate.setHours(23, 59, 59, 59));
        this.reopeningDate.minimumDate = minimumDate;

        return;
      }
      if (action === 'suspend') {
        this.justification.displayDateTime = true;
        this.justification.displaySuspendText = true;
        this.justification.displayWorkingDays = true;
        this.justification.failText = 'Suspender';
      }
      if (action === 'cancel') {
        this.justification.failText = 'Anular';
      }
      if (action === 'revoke') {
        this.justification.failText = 'Revogar';
      }

      this.justification.active = true;
      this.justification.displayFail = true;
      this.justification.displaySuccess = false;
    },
    async openConsiderationModal() {
      this.consideration = true;
    },
    reloadPage() {
      window.location.reload(true);
    },
    async startSession() {
      const unansweredClarifications = this.clarifications.filter(
        esclarecimento => esclarecimento.status === 'CRIADO',
      );
      if (unansweredClarifications.length) {
        this.$alerta('Responda todos os esclarecimentos', 'is-danger');
        return;
      }

      const unansweredImpugnations = this.impugnations.filter(
        impugnacao => impugnacao.status === 'CRIADA',
      );
      if (unansweredImpugnations.length) {
        this.$alerta('Responda todas as impugnações', 'is-danger');
        return;
      }

      this.loading.startSession = true;
      try {
        await this.openAuctionSession();
        await this.buscarItens({ ofertaCompraId: this.auction.id, v2: this.$route.query.v2 });
        this.$alerta('Sessão aberta', 'is-success');
        this.selectedTab = this.$refs.tabs.activeItem.index;
      } catch (error) {
        this.$alerta('Erro ao abrir sessão', 'is-danger');
      }
      this.loading.startSession = false;
    },
    async reopenSession(auctionId) {
      if (new Date(this.auction.data_reabertura) > new Date()) {
        this.$confirmacaoAlerta({
          message: 'A data e/ou horário atuais são menores que a previsão de reabertura.',
          confirmText: 'Entendi',
          type: 'is-danger',
          hasIcon: true,
        });

        return;
      }

      try {
        const confirmation = await this.$confirmacao({
          message: 'Essa ação irá reabrir o pregão eletrônico. Deseja prosseguir?',
          cancelText: 'Cancelar',
          confirmText: 'Continuar',
          type: 'is-info',
          hasIcon: true,
        });
        if (!confirmation) {
          return;
        }

        this.loading.general = true;
        await this.reabrirOfertaCompra(auctionId);
        this.$alerta('Pregão eletrônico reaberto', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao reabrir pregão eletrônico', 'is-danger');
      }
      this.loading.general = false;
    },
    resetLoadings() {
      this.loading.general = false;
      this.disable.getMinutes = false;
      this.loading.fetchAuction = false;
      this.loading.finishSession = false;
      this.loading.revoke = false;
      this.loading.startSession = false;
      this.loading.suspendSession = false;
    },
    async revokeElectronicAuction(data) {
      this.loading.revoke = true;
      try {
        await this.revokeAuction(data);
        this.$alerta('Pregão eletrônico revogado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao revogar pregão eletrônico', 'is-danger');
      }
      this.loading.revoke = false;
      this.$refs.justificationModal.close();
    },
    async selectTab(route) {
      const { aba } = route.query;

      if (aba) {
        if (aba === 'comprador') {
          this.selectedTab = 0;
        }
        if (aba === 'anexos') {
          this.selectedTab = 1;
        }
        if (aba === 'documentos') {
          await this.fetchCompanyDocuments(this.company.id);
          this.selectedTab = 3;
        }
        if (aba === 'esclarecimentos' || aba === 'impugnacoes') {
          if (this.$refs.tabs.items.length > 5) {
            this.selectedTab = 4;
          } else {
            this.selectedTab = 3;
          }

          if (aba === 'esclarecimentos') {
            this.internalTabSelected = 0;
          } else if (aba === 'impugnacoes') {
            this.internalTabSelected = 1;
          }
        }

        if (aba === 'recursos') {
          this.selectedTab = 6;
        }
      } else {
        this.selectedTab = 2;
      }
    },
    async processReopeningDate(dateTime) {
      this.reopeningDate.loading = true;
      try {
        await this.updateReopeningDate({ auctionId: this.auction.id, reopeningDate: dateTime });
        this.$alerta('Data de reabertura inserida', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao inserir data de reabertura', 'is-danger');
      }
      this.reopeningDate.loading = false;
      this.$refs.sendTimeModal.close();
    },
    async sendJustification({ justification, dateTime }) {
      if (this.action === 'suspend') {
        await this.suspendSession({
          auctionId: this.auctionId,
          reopenDate: dateTime,
          justification,
        });
      }
      if (this.action === 'revoke') {
        await this.revokeElectronicAuction({
          auctionId: this.auctionId,
          justification,
        });
      }
      if (this.action === 'cancel') {
        await this.cancelElectronicAuction({
          auctionId: this.auctionId,
          justification,
        });
      }
    },
    situationName(situation) {
      if (situation === 'Lances' && this.closedDisputeMode && this.closedMode) {
        return 'Lances - Fechado';
      }
      if (situation === 'Lances' && this.closedDisputeMode) {
        return 'Lances - Aberto';
      }
      if (situation === 'Habilitação' && this.adhesionStep) {
        return 'Habilitação da Adesão';
      }
      if (situation === 'Fracassada') return 'Fracassado';
      if (situation === 'Deserta') return 'Deserto';
      if (situation === 'Revogada') return 'Revogado';
      if (situation === 'Encerrada com Vencedor') return 'Encerrado com Vencedor';
      if (situation === 'Encerrada com Recurso') return 'Encerrado com Recurso';
      if (situation === 'Suspensa') return 'Suspenso';
      return situation;
    },
    async suspendSession(data) {
      this.loading.suspendSession = true;
      try {
        await this.suspendAuction(data);
        this.$alerta('Pregão eletrônico suspenso', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao supender pregão eletrônico', 'is-danger');
      }
      this.loading.suspendSession = false;
      this.$refs.justificationModal.close();
    },
    verifySituation(step) {
      if (Array.isArray(step)) {
        return step.indexOf(this.situation) > -1;
      }
      return this.situation === step;
    },
    async watchElectronicAuction() {
      const confirmation = await this.$confirmacao({
        message: `Você está ${
          this.auctionWatch.id ? 'desabilitando' : 'habilitando'
        } a opção para receber todas as notificações desse pregão. Deseja prosseguir?`,
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-info',
        hasIcon: true,
      });
      if (!confirmation) {
        return;
      }

      this.loading.watchAuction = true;
      try {
        await this.updateAuctionWatch({ auctionId: this.auction.id });
        this.$alerta(`Opção ${this.auctionWatch.id ? 'habilitada' : 'desabilitada'}`, 'is-success');
      } catch (error) {
        this.$alerta(
          `Erro ao ${this.auctionWatch.id ? 'habilitar' : 'desabilitar'} essa opção`,
          'is-danger',
        );
      }
      this.loading.watchAuction = false;
    },
  },
  async created() {
    this.$onBus('detalhe', this.displayDetails);
    this.$onBus('documentos', this.displaySendDocuments);
    this.$onBus('voltar', this.displayAuctionItems);

    const promises = [
      this.fetchAuction(this.$route.params.id),
      this.fetchMessages(this.$route.params.id),
      this.fetchDocumentTypes(),
    ];
    if (this.userFunction !== funcoes.VISITANTE) {
      promises.push(this.fetchUserLoggedBidder(this.$route.params.id));
    }
    await Promise.all(promises);

    await this.enterAuctionRoom();
    setTimeout(() => {
      this.fetchParticipants(this.$route.params.id);
    }, 50);

    if (this.proposalsDelivery && (this.provider || this.isBidder)) {
      await this.fetchCompanyDocuments(this.company.id);
      if (this.isBidder) {
        await this.fetchAuctionBidderDocuments({
          auctionId: this.$route.params.id,
          bidderId: this.userLoggedBidder.id,
        });
      }
    }
    this.loaded = true;
  },
};
</script>

<style>
.action-button {
  margin-bottom: 0.3rem;
}

.auction-container {
  margin: 0.5rem 0.2rem 1rem;
}

.auction-info {
  width: 300px;
}

.components {
  margin-top: 1rem !important;
}

.custom-icon {
  vertical-align: middle;
}

.disconnection-notification {
  border: unset;
}

.hero-body {
  padding: 1rem !important;
}

.icon-pending-company {
  vertical-align: middle;
}

iframe {
  width: 100%;
  height: 50vh;
}

.imminent-closure-text {
  margin-top: 1.5rem;
  margin-bottom: 2rem !important;
}

.level-button {
  margin-top: 1rem;
  margin-right: 0.2rem;
}

.level-column {
  flex-grow: 0 !important;
}

.level-item {
  margin: 0 1rem;
}

.message-pending-company {
  padding: 5px;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.notification-link {
  color: #2153b5 !important;
  font-weight: 600;
}

.notification-icon {
  vertical-align: middle;
}

.notification-text {
  margin-left: 0.5rem;
}

.minutes-pdf-download {
  margin: 2rem;
  text-align: center;
}

.minutes-pdf-download-text {
  margin-bottom: 1rem;
}

.text-pending-company {
  font-weight: 800;
  margin-left: 0.2rem;

  color: yellow;
}

@media (min-width: 300px) and (max-width: 1170px) {
  .column-direction-touch {
    flex-direction: column !important;
  }

  .margin-1 {
    margin-bottom: 1rem !important;
  }
}
</style>
