<template>
  <div class="buyer-finished-container">
    <div class="title-container is-flex">
      <h2 class="title is-4">Encerradas</h2>

      <h4 class="subtitle is-6 is-italic ml-4" id="custom-subtitle">
        licitações com itens não finalizados
      </h4>
    </div>

    <div v-if="!data.length" class="panel">
      <div class="panel-block is-justify-content-center">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nenhuma licitação.</p>
          </div>
        </section>
      </div>
    </div>

    <div v-if="data.length" class="panel">
      <div class="panel-block">
        <b-table
          class="table is-fullwidth table-typography-size buyer-finished-table"
          :data="data"
          :loading="tableLoading"
        >
          <b-table-column sortable field="type" label="Tipo" v-slot="props">
            <b-tooltip
              :label="props.row.type === 'dispensation' ? 'Dispensa' : 'Pregão'"
              :position="mobile ? 'is-left' : 'is-top'"
            >
              <b-icon
                class="custom-icon"
                type="is-primary"
                :icon="props.row.type === 'dispensation' ? 'alpha-d-box' : 'alpha-p-box'"
              ></b-icon>
            </b-tooltip>
          </b-table-column>

          <b-table-column centered sortable field="biddingNumber" label="Licitação" v-slot="props">
            <router-link
              v-if="props.row.situationId > 1"
              :to="{
                name: props.row.type === 'dispensation' ? 'Dispensa' : 'Pregao',
                params: { id: props.row.id },
              }"
            >
              {{ props.row.biddingNumber }}
            </router-link>
            <p v-else>{{ props.row.biddingNumber }}</p>
          </b-table-column>

          <b-table-column
            centered
            label="Itens com Ações Restantes"
            v-slot="props"
            :visible="
              !tableLoading &&
              data.some(
                bidding =>
                  bidding.missingAdjudications ||
                  bidding.missingHomologations ||
                  bidding.missingRatifications,
              )
            "
          >
            {{ getMissingValidations(props.row) }}
          </b-table-column>

          <template v-slot:footer>
            <AppPagination
              :data-filters="{
                page: filters.page,
                perPage: filters.perPage,
              }"
              :isStateURLEnabled="false"
              :number-lines="10"
              :total="count"
              @change-page="page => changePage(page)"
              @change-per-page="perPage => changePerPage(perPage)"
            />
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AppPagination from '@/components/AppPagination';
import { formatarData as formatDate } from '@/utils/format';

export default {
  name: 'BuyerFinishedBiddings',
  components: {
    AppPagination,
  },
  props: {
    count: { type: Number, default: 0 },
    data: { type: Array, default: () => [] },
    mobile: { type: Boolean, default: false },
    tableLoading: { type: Boolean, default: false },
  },
  filters: {
    formatDate,
  },
  data() {
    const filters = {
      page: 1,
      perPage: 10,
    };

    const loading = {
      general: false,
    };
    return {
      filters,
      loading,
    };
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      user: 'getUser',
    }),
    displayExclusiveColumn() {
      return this.data.some(
        row =>
          row.participation === 'AMPLOCOTA' ||
          row.participation === 'RESERVADO' ||
          row.participation === 'EXCLUSIVA',
      );
    },
  },
  methods: {
    ...mapActions(['excluirDispensa', 'excluirOfertaCompra']),
    changePage(page) {
      this.filters.page = page;
      this.$emit('filter-buyer-finished-biddings', {
        page: this.filters.page,
        perPage: this.filters.perPage,
      });
    },
    changePerPage(perPage) {
      this.filters.page = Number(perPage) > Number(this.filters.perPage) ? 1 : this.filters.page;
      this.filters.perPage = perPage;
      this.$emit('filter-buyer-finished-biddings', {
        page: this.filters.page,
        perPage: this.filters.perPage,
      });
    },
    async deleteBidding(id, type) {
      const confirmation = await this.$confirmacao({
        message: 'Tem certeza que deseja excluir a licitação?',
      });
      if (!confirmation) {
        return;
      }

      this.loading.general = true;
      try {
        if (type === 'dispensation') {
          await this.excluirDispensa(id);
        } else {
          await this.excluirOfertaCompra(id);
        }
        this.$parent.deleteBidding(id, type);
      } catch (error) {
        this.$alerta('Erro ao excluir licitação', 'is-danger');
      } finally {
        this.loading.general = false;
      }
    },
    displayExclusiveIcon(row) {
      return (
        row.participation === 'AMPLOCOTA' ||
        row.participation === 'RESERVADO' ||
        row.participation === 'EXCLUSIVA'
      );
    },
    async editBidding(id, type) {
      if (type === 'dispensation') {
        this.$router.push({ name: 'DispensaEditar', params: { id } });
      } else {
        this.$router.push({ name: 'PregaoEditar', params: { id } });
      }
    },
    getMissingValidations(row) {
      if (!row.missingAdjudications && !row.missingHomologations && !row.missingRatifications) {
        return '';
      }
      if (row.type === 'dispensation') {
        return `${row.missingRatifications} para ratificar`;
      }
      if (row.missingAdjudications && row.missingHomologations) {
        return `${row.missingAdjudications} para adjudicar e ${row.missingHomologations} para homologar`;
      }

      return row.missingAdjudications
        ? `${row.missingAdjudications} para adjudicar`
        : `${row.missingHomologations} para homologar`;
    },
  },
};
</script>

<style lang="scss" scoped>
.buyer-column {
  min-width: 150px;
}

.buyer-finished-container {
  margin-left: 10px;
  width: 50%;
}

@media (max-width: 1160px) {
  .buyer-finished-container {
    padding-top: 3rem;
    width: 100%;
  }
}
</style>

<style lang="scss">
#buyer-indexed-options .field-body .field {
  justify-content: center;
}

#custom-subtitle {
  color: #979797;
  font-size: 0.8rem;
  margin-top: 9px;
}
</style>
